<template>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area">
          <div class="container containerDash">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sidebar-dashboard></sidebar-dashboard>
              </div>

              <!-- ///// -->
              <div class="col-lg-9">
                <h3 style="">Lost Auction</h3>
             
                <div v-if="!items.length">
                  <notfound :checkdata="initialLoading"></notfound>
                </div>
                <!-- <div> -->
                <div v-else class="b-table-cls">
                  <div
                  style="
                    float: right;
                    margin-right: 5px;
                    margin-bottom: 10px;
                    margin-top: -38px;
                  "
                >
                  <button
                    class="btn btn-primary noPrint"
                    :style="printBtn"
                    @click="printCom"
                  >
                    Print
                  </button>
                </div>
                  <b-table
                    id="printMe"
                    :items="items"
                    :fields="fieldData"
                    responsive
                    stacked="sm"
                    :per-page="perPage"
                  >
                    <template #cell(reauctionStatus)="row">
                      <button
                        v-if="showReauctionButton(row.item)"
                        @click="reauctionRequest(row.item)"
                        :disabled="disableReauctionButton(row.item)"
                        class="btn btn-primary"
                      >
                        {{ getReauctionButtonText(row.item) }}
                      </button>
                      <span v-else>{{ row.item.reauctionStatus }}</span>
                    </template>
                  </b-table>
                </div>
                <div v-if="items.length" class="overflow-auto">
                  <div class="mt-3">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="center"
                      pills
                      first-number
                      last-number
                    ></b-pagination>
                  </div>
                </div>
              </div>

              <!-- ///// -->
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import userService from "@/services/user.service";
// import moment from "moment";
export default {
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      customerList: [],
      initialLoading: true,
      fieldData: [
        { key: "aucId", label: "Id" },
        { key: "aucName", label: "Name" },
        { key: "vehRegNo", label: "Registration" },
        {
          key: "vehPrice",
          label: "Price",
          formatter: (vehPrice) =>
            vehPrice.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            }),
        },
        {
          key: "latestBid",
          label: "Top Bid ",
          formatter: (latestBid) =>
            latestBid.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            }),
        },
        {
          key: "aucMinWinAmount",
          label: "Min Win",
          formatter: (aucMinWinAmount) =>
            aucMinWinAmount.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            }),
        },
        {
          key: "aucDate",
          label: "Start Date",
        },
        {
          key: "aucEndDate",
          label: "End Date",
        },
        {
          key: "reauctionStatus",
          label: "Auction Status",
        },
      ],

      printBtn: "display:block",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    items() {
      return this.customerList.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    totalRows() {
      return this.customerList.length;
    },
  },
  created() {
    if (this.loggedIn) {
      if (
        !this.$store.state.auth.user[0].sellerId == "" ||
        !this.$store.state.auth.user[0].sellerId == " "
      ) {
        this.$router.push("/Lost").catch(() => {
          "home";
        });

        this.getLostAuction(this.$store.state.auth.user[0].sellerId);
      } else {
        this.$router.push("/").catch(() => {
          "home";
        });
      }
    }
  },
  mounted() {
    if (this.$store.state.auth.user) {
      this.st = this.$store.state.auth.user[0];
    } else {
      this.$router.push("/").catch(() => {
        "myaccount";
      });
    }
  },
  methods: {
    makeToast(variant, body_content) {
      let tittleMessage = variant;
      if (variant == "danger") {
        tittleMessage = "Error";
      }
      if (variant == "success") {
        tittleMessage = "Success";
      }
      if (variant == "warning") {
        tittleMessage = "Warning";
      }
      this.$bvToast.toast(body_content, {
        title: `${tittleMessage || "default"}`,
        variant: variant,
        autoHideDelay: 5000,
        //solid: true,
        toaster: "b-toaster-bottom-right",
        appendToast: true,
      });
    },
    showReauctionButton(item) {
      return item.reauctionStatus !== "Reauctioned";
    },
    disableReauctionButton(item) {
      return (
        item.reauctionStatus === "Reauctioned" ||
        (item.isRequsted == 1 && item.reauctionStatus !== "Reauctioned")
      );
    },
    getReauctionButtonText(item) {
      if (item.reauctionStatus === "Reauctioned") {
        return "Reauctioned";
      } else if (
        item.isRequsted == 1 &&
        item.reauctionStatus !== "Reauctioned"
      ) {
        return "Requested";
      } else {
        return "Reauction";
      }
    },
    reauctionRequest(item) {
      // console.log("Reauction requested for:", item);
      let sellerId = this.$store.state.auth.user[0].sellerId;
      // reauctionRequst
      if (sellerId == "undefind") {
        console.log("undefind");
      } else {
        userService
          .reauctionRequst(item.aucId, sellerId)
          .then((response) => {
            console.log(response);
            this.makeToast("success", "Successfully requsted");
            this.initialLoading = false;
            setTimeout(() => {
              location.reload();
            }, 3000);
          })
          .catch((e) => {
            console.log(e);
            this.initialLoading = false;
            this.message = e.response.data.Message;
            this.makeToast("danger", this.message);
          });
      }
    },
    printCom() {
      window.print();
    },
    getLostAuction(sellerId) {
      // console.log(sellerId);
      if (sellerId == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getLostAuction(sellerId)
          .then((response) => {
            this.customerList = response.data.Data;
            this.initialLoading = false;
          })
          .catch((e) => {
            console.log(e);
            this.initialLoading = false;
          });
      }
    },
  },
};
</script>
<style scoped>
th label {
  margin-bottom: 0px;
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}
table tr {
  background-color: #fff;
  border: 1px solid #e1e5e9;
  padding: 0.35em;
  border-radius: 3px;
}
table thead tr:first-child {
  border: 1px solid #0094da;
}
table th,
table td {
  padding: 8px;
  text-align: left;
  color: #202020;
  font-size: 14px;
}

table td:nth-child(4) {
  font-size: 18px;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background: #0094da;
  color: #fff;
}

table tbody tr td .btn-invoice {
  background: #0094da;
  color: #fff;
  font-size: 13px;
  padding: 10px 20px;
  border: 0;
  width: 100%;
  border-radius: 5px;
}

tbody tr:nth-child(even) {
  background-color: #eee;
}

tbody tr:nth-child(odd) {
  background-color: #fff;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }
  table caption {
    font-size: 1.3em;
  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
    padding: 0;
  }
  table tr {
    border-bottom: 3px solid #e1e5e9;
    display: block;
    margin-bottom: 0.625em;
  }
  table th,
  table td {
    padding: 0.625em;
  }
  table td {
    border-bottom: 1px solid #e1e5e9;
    display: block;
    font-size: 0.8em;
    text-align: right;
    color: #9da9b9;
  }
  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    color: #656971;
  }
  table td:last-child {
    border-bottom: 0;
  }
  table td:nth-child(4) {
    font-size: 0.8em;
  }
}

@media print {
  #navbarSupportedContent {
    display: none !important;
  }
  .account_border-right {
    display: none !important;
  }
  .noPrint {
    display: none !important;
    visibility: hidden;
  }
}
</style>
